var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "development") {
  console.log(
    `Logging to Sentry.io is skipped in '${process.env.NODE_ENV}' environments.`
  );
} else {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://27f2e6397f274a649b6f95bf8d7d080b@o1363799.ingest.sentry.io/4504347771142144",
    // Adjust this value in production, or use tracesSampler for greater control
    /**
     * @dev - tracesSampler allows dynamic sampling rate, to distinguish between more vs less important errors
     *
     * See for more details: https://docs.sentry.io/platforms/javascript/configuration/sampling/#setting-a-sampling-function
     **/
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
