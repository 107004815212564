import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { hotjar } from "react-hotjar";
import Script from "next/script";
import * as gtag from "../../lib/gtag";
import "styles/src/sass/index.scss";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { wrapper } from "../store";
import LoadingSpinner from "../components/layout/LoadingSpinner";
import SignUpModal from "../components/modal/SignUpModal"
import useModal from '../../src/hooks/useModal';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { isModalOpen, toggleVisibility } = useModal();
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [hasShownModal, setHasShownModal] = useState(false);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
      hotjar.initialize(
        process.env.NEXT_PUBLIC_HOTJAR_HJID,
        process.env.NEXT_PUBLIC_HOTJAR_HJSV
      );
    }

    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => { // For signup modal
    const handleMouseLeave = (event) => {
      if (event.clientY <= 10 && !hasShownModal) {
        toggleVisibility();
        setHasShownModal(true);
      }
    };
  
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;
  
      const isScrollingDown = window.scrollY > lastScrollTop;
  
      if (scrollPosition >= bottomPosition && isScrollingDown) {
        toggleVisibility();
      }
  
      setLastScrollTop(window.scrollY);
    };
  
    const { pathname } = router;
    const allowedPaths = ['/', '/art', '/registrations', '/food'];
    const shouldShowModal = allowedPaths.includes(pathname) || pathname.startsWith('/blog');

    if (shouldShowModal) {
      window.addEventListener('scroll', handleScroll);
      document.addEventListener("mouseleave", handleMouseLeave);
    }
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [router.pathname, lastScrollTop, hasShownModal]);

  function RenderGaTracking() {
    let innerHtml = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtag.GA_TRACKING_ID}', {
        page_path: window.location.pathname,
      });
    `;

    if (process.env.NODE_ENV !== "development") {
      /**
       * @description Adds ncessary page_path tracking on initial mount (in comparison to a Next/Router page change)
       */
      innerHtml += `gtag('config', '${gtag.GA_TRACKING_ID}', {
        page_path: window.location.pathname,
      });`;
    }

    return (
      <>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
        />
      </>
    );
  }

  function RenderReactQueryDevTools() {
    if (process.env.NODE_ENV === "development") {
      return <ReactQueryDevtools initialIsOpen={false} />;
    }

    return null;
  }

  if (pageProps.statusCode === 404) {
    return <PageNotFound />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RenderGaTracking />
      <Hydrate state={pageProps.dehydratedState}>
        <Component {...pageProps} />
      </Hydrate>
      <RenderReactQueryDevTools />
      <SignUpModal 
        isModalOpen={isModalOpen}
        handleModalClose={toggleVisibility}/>
    </QueryClientProvider>
  );
}

export default wrapper.withRedux(MyApp);

function PageNotFound() {
  useEffect(() => {
    if (window) {
      window.location = "/";
    }
  }, []);

  return <LoadingSpinner />;
}
