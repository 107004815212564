import useClickOutside from './useClickOutside';

/**
 * @description this is just a wrapper around the useClickOutside custom hook. It is used to ensure the backdrop is used to detect when the modal is open.
 */
const useModal = () => {
  const { isVisible, toggleVisibility } = useClickOutside({ useBackdrop: true });

  return {
    isModalOpen: isVisible,
    toggleVisibility,
  };
};

export default useModal;
