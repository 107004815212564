import { PropsWithChildren } from 'react';

interface IModalFooterProps extends PropsWithChildren{}

const ModalFooter: React.FC<IModalFooterProps> = ({ children }) => {
  return (
    <div className="modal-footer">
      <>{children}</>
    </div>
  );
};

export default ModalFooter;
