import { NextAppEnvType } from "./components/types";

const FORMPAY_CLIENT_BASE_URLS = {
  development: "http://localhost:3000",
  production: "https://www.getformpay.com",
  staging: "https://getformpay-staging.onrender.com",
  test: "http://localhost:3000",
};

const MERCHANT_UI_BASE_URLS = {
  development: "http://localhost:3002",
  production: "https://admin.getformpay.com",
  staging: "https://merchant-ui-staging.onrender.com/",
  test: "http://localhost:3002",
};

const FORMPAY_API_BASE_URLS = {
  development: "http://localhost:3001",
  production: "https://formpay-rails.onrender.com",
  staging: "https://formpay-rails-staging.onrender.com",
  test: "http://localhost:3001",
};

export const FORMPAY_API_BASE_URL =
  FORMPAY_API_BASE_URLS[
    (process.env.NEXT_PUBLIC_APP_ENV as NextAppEnvType) || process.env.NODE_ENV
  ];
export const FORMPAY_CLIENT_BASE_URL =
  FORMPAY_CLIENT_BASE_URLS[
    (process.env.NEXT_PUBLIC_APP_ENV as NextAppEnvType) || process.env.NODE_ENV
  ];
export const MERCHANT_UI_BASE_URL =
  MERCHANT_UI_BASE_URLS[
    (process.env.NEXT_PUBLIC_APP_ENV as NextAppEnvType) || process.env.NODE_ENV
  ];

export const STRIPE = "stripe";
export const STRIPE_CONNECT_DASHBOARD_URL =
  "https://dashboard.stripe.com/connect/accounts/";

export const DEFAULT_VALUE = "defaultValue";
