import React, { PropsWithChildren } from 'react';

interface IModalProps extends PropsWithChildren {
  isModalOpen: boolean;
  classes?: string;
}

const Modal: React.FC<IModalProps> = ({ children, isModalOpen, classes }) => {
  return (
    <>
      {isModalOpen && (
        <>
          <div
            id="modal-backdrop"
            className="modal fade show"
            aria-modal="true"
          >
            <div className="modal-dialog">
              <div className={`modal-content ${classes}`}>
                {/** TODO: update styles repo and stop using inline styles */}
                <i
                  id="modal-close"
                  style={{
                    fontSize: 30,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    cursor: 'pointer',
                    color: '#666',
                  }}
                  className="bi bi-x"
                ></i>
                {children}
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export default Modal;
