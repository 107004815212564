import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import { ReactElement } from 'react';

interface IBaseModalProps {
  headerContent?: ReactElement | ReactElement[];
  bodyContent: ReactElement | ReactElement[];
  footerContent?: ReactElement | ReactElement[];
  isModalOpen: boolean;
  classes?: string;
}

const BaseModal: React.FC<IBaseModalProps> = ({ isModalOpen, headerContent, bodyContent, footerContent, classes }) => {
  return (
    <Modal isModalOpen={isModalOpen} classes={classes}>
      {headerContent &&
        <ModalHeader>{headerContent}</ModalHeader>
      }
      <ModalBody>{bodyContent}</ModalBody>
      {footerContent &&
        <ModalFooter>{footerContent}</ModalFooter>
      }
    </Modal>
  );
};

export { Modal, ModalHeader, ModalBody, ModalFooter };
export default BaseModal;
