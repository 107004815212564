import React from "react";
import { useTranslation } from "react-i18next";

export default function LoadingSpinner() {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center mt-5 mb-5">
      <div className="spinner-border" role="status">
        <span className="sr-only">{t("Loading")}...</span>
      </div>
    </div>
  );
}
