import React from 'react';
import BaseModal from './baseModal';
import SignUpButton from '../landers/SignUpButton';

interface ISignUpModalProps {
  isModalOpen: boolean;
}

const SignUpModal: React.FC<ISignUpModalProps> = ({
  isModalOpen,
}) => {

  return (
    <BaseModal
      isModalOpen={isModalOpen}
      classes="cta--modal"
      headerContent={<h4 className="cta__heading mb-0">Start selling online in minutes with FormPay — for free</h4>}
      bodyContent={
        <>
          <p className="cta__body">Take the hassle out of online selling. Sign up for FormPay and get your store up and running in minutes.</p>
          <div className="text-center">
            <img className="cta__img" alt="FormPay demo store" src="https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/3928eb53-53aa-4ac6-6db2-9f7066bc0400/public" />
          </div>
          <SignUpButton classes="btn-block btn-lg" label="Start for free" gtagLabel="signup_modal" />
          <p className="text-subtle text-center mt-2">No credit card needed.</p>
        </>
      }
    />
  );
};

export default SignUpModal;
