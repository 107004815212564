export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  const payload = {
    page_path: url,
  };

  if (process.env.NODE_ENV === "development") {
    return _logPayloadToConsole(payload);
  } else {
    window.gtag("config", GA_TRACKING_ID, payload);
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  const payload = {
    event_category: category,
    event_label: label,
    value: value,
  };

  if (process.env.NODE_ENV === "development") {
    return _logPayloadToConsole(payload);
  } else {
    window.gtag("event", action, payload);
  }
};

const _logPayloadToConsole = (payload) => {
  console.log(
    "GA tracking is disabled in development. The payload is:",
    payload
  );
  return;
};
