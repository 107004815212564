import { SyntheticEvent, useEffect, useRef, useState } from 'react';

interface IUseClickOutsideOptions {
  useBackdrop?: boolean;
}

/**
 * @description conditionally pass the containerRef into the outer most HTMLDivElement you want to close when clicking outside of the HTML div element (the containerRef should only be pased when the backdrop/modal is not being used).
 */
const useClickOutside = (options?: IUseClickOutsideOptions) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleVisibility = (e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      /**
       * NOTE: Stopping the propagation of the event is necessary to prevent the event from bubbling up to the window and opening other React components.
       * i.e. Order details open when the order status dropdown is clicked.
       */
      e.stopPropagation();
    }
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (e: MouseEvent) => {
    /**
     * NOTE: do not prevent the default behaviour of the click event, as it will prevent the click event from being triggered on any element outside of the containerRef.
     * i.e. checkboxes will not be checked/unchecked if default behaviour is prevented.
     */
    const isModal = options?.useBackdrop;
    const isBackdropClicked = e.target instanceof HTMLElement && e.target.id === 'modal-backdrop';
    const isModalCloseIconClicked = e.target instanceof HTMLElement && e.target.id === 'modal-close';
    const isContainerRefUsed = e.target instanceof HTMLElement && containerRef.current;

    if (isModal && (isBackdropClicked || isModalCloseIconClicked)) {
      setIsVisible(false);
    } else {
      if (isContainerRefUsed) {
        if (!containerRef.current?.contains(e.target)) {
          setIsVisible(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isVisible]);

  return { containerRef, isVisible, toggleVisibility };
};

export default useClickOutside;
