import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { HYDRATE } from "next-redux-wrapper";
// import { IForm } from "../components/interfaces";

// type UserDetailsType = {
//   name_initials: string;
//   full_name: string;
//   form: IForm;
// } | null;
// interface IUserState {
//   userDetails: UserDetailsType;
//   isAuthenticated: boolean;
// }

// Initial state
const initialState: any = {
  userDetails: null,
  currentUserToken: null,
  isAuthenticated: false,
};

// Actual Slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Action to set the form status
    setUserState(state, action) {
      state.userDetails = action.payload.userDetails;
      state.currentUserToken = action.payload.currentUserToken;
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-expect-error improper reducer type
      [HYDRATE]: (state, action) => {
        // TODO: fix type
        return {
          ...state,
          ...action.payload.form,
        };
      },
    },
  },
});

export const { setUserState } = userSlice.actions;

export const selectUserState = (state: AppState) => state.user;

export default userSlice.reducer;
