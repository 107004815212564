import { configureStore } from "@reduxjs/toolkit";
import { formSlice } from "./slices/formSlice";
import { userSlice } from "./slices/userSlice";
import { createWrapper } from "next-redux-wrapper";

const rootReducer = {
  [formSlice.name]: formSlice.reducer,
  [userSlice.name]: userSlice.reducer,
};

const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "development",
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
// @ts-expect-error instance of Redux store instead of a store itself
export type AppDispatch = typeof makeStore.dispatch;

export const wrapper = createWrapper<AppStore>(makeStore);
