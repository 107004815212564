import React from "react";
import Link from "next/link";
import { MERCHANT_UI_BASE_URL } from "../../constants";
import * as gtag from "../../../lib/gtag";

interface ISignUpButtonProps {
  classes?: string;
  label?: string;
  gtagLabel?: string;
}

export default function SignUpButton({ classes, label = "Start Selling", gtagLabel }: ISignUpButtonProps) {
  const handleSignUpButtonClick = () => {
    gtag.event({
      action: "click_signup_button",
      category: "signup_button_clicks",
      label: gtagLabel || "home",
      value: undefined
    });
  };

  return (
    <>
      <Link href={`${MERCHANT_UI_BASE_URL}/signup`}>
        <a className={`btn btn-primary ${classes}`} onClick={handleSignUpButtonClick}>
          {label}
        </a>
      </Link>
    </>
  );
}
