import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { HYDRATE } from "next-redux-wrapper";
import {
  ParentBlockErrorType,
  StaticBlockErrorType,
} from "../components/types";

export interface IFormState {
  isSubmitting: boolean;
  formValidationFailed: boolean;
  allStaticErrors: StaticBlockErrorType[];
  parentBlockErrors: ParentBlockErrorType[] | null;
  promoCode: {
    name: string;
    discountAmount: number;
  };
  delivery_info?: {
    price: number;
  };
  pickup_info?: {
    price: number;
  };
}

const initialState: any = {
  formState: {
    isSubmitting: false,
    formValidationFailed: false,
  },
  orderState: {
    /**
     * @description payButtonClickCount at this time is only being used to track whether or not any `Pay` button is clicked, to couple with scrolling into the first pb error view.
     * This prevents the form from scrolling into error view on every re-render.
     */
    payButtonClickCount: 0,
    errorMessage: "",
  },
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    // Action to set the form status
    setFormState(state, action) {
      state.formState = action.payload;
    },
    setFormIsSubmitting(state, action) {
      state.formState.isSubmitting = action.payload;
    },
    setOrderState(state, action) {
      state.orderState = action.payload;
    },
    setOrderErrorMessage(state, action) {
      state.orderState.errorMessage = action.payload;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-expect-error improper reducer type
      [HYDRATE]: (state, action) => {
        // TODO: fix type
        return {
          ...state,
          ...action.payload.form,
        };
      },
    },
  },
});

export const {
  setFormState,
  setFormIsSubmitting,
  setOrderState,
  setOrderErrorMessage,
} = formSlice.actions;

export const selectFormState = (state: AppState) => state.form.formState;
export const selectOrderState = (state: AppState) => state.form.orderState;

export default formSlice.reducer;
