import { PropsWithChildren } from 'react';

interface IModalBodyProps extends PropsWithChildren{}

const ModalBody: React.FC<IModalBodyProps> = ({ children }) => {
  return (
    <div className="modal-body">
      <>{children}</>
    </div>
  );
};

export default ModalBody;
