import { PropsWithChildren } from 'react';

interface IModalHeaderProps extends PropsWithChildren{}

const ModalHeader: React.FC<IModalHeaderProps> = ({ children }) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">
        <>{children}</>
      </h5>
    </div>
  );
};

export default ModalHeader;
